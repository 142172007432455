:root {
    --primary: #161F6D;
    --secondary: #00ABE1;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes downUp {
    0% {
        bottom: -20px;
    }
    100% {
        bottom: 0;
    }
}

@keyframes upDown {
    0% {
        top: -20px;
    }
    100% {
        top: 0;
    }
}

.downUp {
    animation: downUp linear alternate;
    position: relative;
    /*background-color: red;*/
    /*animation-name: example;*/
    animation-duration: 200ms;
}

.upDown {
    animation: upDown linear alternate;
    position: relative;
    /*background-color: red;*/
    /*animation-name: example;*/
    animation-duration: 200ms;
}

.fancyButton {
    background: linear-gradient(to right, #161F6D, #00ABE1);
}

.fancyBackground {
    background: linear-gradient(to right, #161F6D, #00ABE1);
}

.menuItemActive {
    background: linear-gradient(to right, #00ABE1, #161F6D) !important;
}

.noCursorPointer {

}

.noCursorPointer:hover {
    cursor: default !important;
}

a {
    text-decoration: none;
    color: inherit;
}

a.active .MuiListItemButton-root {
    background: linear-gradient(to right, #00ABE1, #161F6D) !important;
}

/* Homepage */
.serviceCategoryDescriptionOdd {
    background: linear-gradient(to right, #161F6D, #0B65A7);
}

.serviceCategoryDescriptionEven {
    background: linear-gradient(to right, #0B65A7, #00ABE1);
}

.linkAvatar {
    background-color: rgba(244, 244, 244, 0.3) !important;
    border: 2px solid white !important;
    color: white;
    width: 70px !important;
    height: 70px !important;
    position: absolute !important;
    top: calc(50% - 35px) !important;
    left: calc(50% - 35px) !important;
    transform: rotateY(0deg) rotate(45deg) !important;
    z-index: 3 !important;
}

.linkAvatar:hover {
    background-color: rgba(244, 244, 244, 0.7) !important;
    border: 3px solid white !important;
    cursor: pointer;
    width: 80px !important;
    height: 80px !important;
    transition: linear 200ms;
    top: calc(50% - 40px) !important;
    left: calc(50% - 40px) !important;
}

.linkAvatar:hover .serviceCategoryLinkIcon {
    font-size: 50px !important;
    transition: linear 200ms;
}

.serviceCategoryLinkIcon {
    font-size: 40px !important;
}

.homepageServiceImage {
    overflow: hidden !important;
}

.homepageServiceImage:hover {
    opacity: 0.9 !important;
    transform: scale(1.05) rotateY(0deg) rotate(1deg) !important;
    overflow: hidden !important;
    transition: linear 200ms;
}

.iconButtonMenuItemStack:hover {
    background: #f3f3f3 !important;
}

.iconButtonMenuItem {
    width: 100% !important;
}

.iconButtonMenuItem:hover {
    background: none !important;
}

.noHoverButton:hover {
    /*background: none !important;*/
}

.blob-effect {
    position: relative;
    width: 220px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blob-effect span:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #00ABE1;
    border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
    transition: 0.5s;
    animation: rotate-blob 5s linear infinite;
}

/*.blob-effect:hover span:nth-child(1) {*/
/*  background: #d76bb1;*/
/*  border: none;*/
/*}*/

.blob-effect span:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #161F6D;
    border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
    transition: 0.5s;
    animation: rotate-blob 4s linear infinite;
}

/*.blob-effect:hover span:nth-child(2) {*/
/*  background: #f192d0;*/
/*  border: none;*/
/*}*/

.blob-effect span:nth-child(3) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #00ABE1;
    border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
    transition: 0.5s;
    animation: rotate-blob2 10s linear infinite;
}

/*.blob-effect:hover span:nth-child(3) {*/
/*  background: #f06ec294;*/
/*  border: none;*/
/*}*/

@keyframes rotate-blob {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate-blob2 {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.div-container {
    position: relative;
    padding: 30px 45px;
    color: inherit;
    text-align: center;
    transition: 0.5s;
    z-index: 10000;
}

.div-container p {
    /*color: #fff;*/
}

/*.div-container a {*/
/*  position: relative;*/
/*  display: inline-block;*/
/*  margin-top: 10px;*/
/*  border: 2px solid #fff;*/
/*  padding: 6px 18px;*/
/*  text-decoration: none;*/
/*  color: #fff;*/
/*  font-weight: 600;*/
/*  border-radius: 73% 27% 44% 56% / 49% 44% 56% 51%;*/
/*}*/

/*.div-container a:hover {*/
/*  background: #fff;*/
/*  color: #333;*/
/*}*/

.css-1bb0qnm-MuiTimelineItem-root:before {
    content: "";
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 0 !important;
    /* padding: 6px 16px; */
}

.css-waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    min-height: 80px;
    max-height: 120px;
}

/* Here we declare the SVG node that we wish to animate. */

.animated-waves > use {
    animation: infinite-waves 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.animated-waves > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.animated-waves > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.animated-waves > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.animated-waves > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes infinite-waves {
    0% {
        transform: translate3d(-90px, 0, 0);
    }
    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .css-waves {
        height: 40px;
        min-height: 40px;
    }
}

html {
    /*background-image:linear-gradient(#69f, #fff);*/
    /*height:100%;*/
    /*overflow:hidden;*/
}

.grass {
    /*background-image:linear-gradient(#69f, #fff);*/
    height: 100%;
    max-width: 300px !important;
    overflow: hidden;
}

.grass > div {
    animation: breeze 8s cubic-bezier(.8, -0.5, .25, 1.5) infinite;
    background-color: #0b0;
    border-right: solid 5px #0c0;
    border-top-right-radius: 100%;
    bottom: 0;
    position: absolute;
    transform-origin: bottom;
    transition: all 100ms ease-in-out;
    width: 3px;
}

@keyframes breeze {
    0%, 100% {
        transform: auto;
    }
    50% {
        transform: rotate(5deg);
    }
}

.sun {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10vh 10vh #fff;
    height: 10vh;
    position: fixed;
    right: 2vh;
    top: 2vh;
    width: 10vh;
}

.houseCardActionsFirst {
    background: #077FBD;
    /*border-right: 1px solid #161F6D;*/
}

.houseCardActionsFirst:hover {
    background: #0588C4;
}

.houseCardActionsSecond {
    background: #0972B2;
}

.houseCardActionsSecond:hover {
    background: #0877B6;
}

.houseCardActionsThird {
    background: #0B63A6;
}

.houseCardActionsThird:hover {
    background: #0A69AA;
}

.houseCardInfoIcon {
    width: 50px !important;
    height: 50px !important;
    color: #888;
    margin-right: auto;
    margin-left: auto;
}

.houseCardConstructionYear {
    color: #16A98F;
}

.houseCardConstructionArea {
    color: #6B459C;
}

.houseCardConstructionFloor {
    color: #A44599;
}

.houseCardConstructionEnergyClass {
    color: #FFC114;
}

.houseCardConstructionHeating {
    color: #F37123;
}

.houseCardConstructionCooling {
    color: #2977B7;
}

.houseCardActions {
    padding: 0 !important;
}

.roomCardInfoIcon {
    width: 50px !important;
    height: 50px !important;
    color: #888;
    margin-right: auto;
    margin-left: auto;
}

.roomCardTemperature {
    color: #FFA500;
}

.roomCardHumidity {
    color: #0080FF;
}

.roomCardWindows {
    color: #800080;
}

.roomCardArea {
    color: #6B459C;
}

.roomCardDevices {
    color: #00BBBB;
}


.chartContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.fancyText {
    font-size: 60px;
    background: -webkit-linear-gradient(45deg, var(--secondary), var(--primary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fancyTextSmall {
    font-size: 26px;
    background: -webkit-linear-gradient(45deg, var(--secondary), var(--primary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.customButton {
    background: linear-gradient(45deg, var(--secondary) 30%, var(--primary) 90%);
    border: 0;
    border-radius: 40px !important;
    color: white;
    height: 48px;
    padding: 0 30px;
}

.responsiveImage {
    width: 100%;       /* Make image take the full width of its container */
    height: auto;      /* Maintain aspect ratio */
    object-fit: contain; /* Ensure image scales properly */
}

.imageContainer {
    display: flex;      /* Use flex to center the image */
    justify-content: center; /* Center horizontally */
}
